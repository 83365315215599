<template>
  <div class="page-coantainer">
    <el-dialog
      title="添加备注"
      :visible.sync="remarkData.isOpen"
      width="30%"
      :before-close="handleClose"
    >
      <div class="dialog-body">
        <el-form ref="form" :model="formData" label-width="80px">
          <el-form-item label="备注">
            <el-input v-model.trim="formData.remarks"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="updateRemark">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getStudentInfoById, updateRemark } from '@/api/api'

export default {
  props: {
    remarkData: {
      type: Object
    }
  },
  data () {
    return {
      isOpen: false,
      formData: {
        remarks: ''
      }
    }
  },
  watch: {
    'remarkData.isOpen' (data) {
      if (data) {
        this.getStudentInfoById()
      }
    }
  },
  methods: {
    /* 获取学生信息 */
    getStudentInfoById () {
      getStudentInfoById(this.remarkData.id).then(res => {
        this.formData.remarks = res.data.remarks
      })
    },
    updateRemark () {
      updateRemark({ ...this.formData, id: this.remarkData.id }).then((res) => {
        this.$emit('closeDialog', {
          isOpen: false,
          type: 'success'
        })
        this.$message({
          message: '添加成功',
          type: 'success'
        })
      })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.$emit('closeDialog', { isOpen: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-coantainer {
  ::v-deep .el-dialog {
    &__body {
      max-height: 500px;
      overflow: auto;
    }
  }
}
</style>
