<template>
  <div class="page-coantainer">
    <el-dialog
      title="更改专业"
      :visible.sync="updateMajorData.isOpen"
      width="30%"
      :before-close="handleClose"
    >
      <div class="dialog-body">
        <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
          <el-form-item label="院校" prop="applySchoolId">
            <el-select v-model="formData.applySchoolId" placeholder="请选择院校">
              <span v-for="(item, index) in schoolList" :key="index">
                <el-option
                  :label="item.schoolName"
                  :value="item.id"
                ></el-option>
              </span>
            </el-select>
          </el-form-item>
          <el-form-item label="专业" prop="applyMajorId">
            <el-select v-model="formData.applyMajorId" placeholder="请选择专业">
              <span v-for="(item, index) in majorList" :key="index">
                <el-option
                  :label="item.majorName"
                  :value="item.id"
                ></el-option>
              </span>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="updateStudent">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSchoolList, getMajorBySchoolId, updateStudent } from '@/api/api.js'

export default {
  props: {
    updateMajorData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        applyMajorId: '',
        applySchoolId: ''
      },
      schoolList: [],
      majorList: [],
      rules: {
        applyMajorId: [
          { required: true, message: '请填写专业', trigger: 'blur' }],
        applySchoolId: [
          { required: true, message: '请选择院校', trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    'updateMajorData.isOpen' (data) {
      if (data) {
        this.getSchoolData()
      }
    },
    'formData.applySchoolId' (data) {
      this.getMajorBySchoolId(data)
    }
  },
  methods: {
    /* 获取学校列表 */
    getSchoolData () {
      getSchoolList({
        currentPage: 1,
        pageSize: 20
      }).then((res) => {
        this.schoolList = res.data.list
      })
    },
    /* 获取专业列表 */
    getMajorBySchoolId (schoolId) {
      getMajorBySchoolId(schoolId).then((res) => {
        this.majorList = res.data
      })
    },
    /* 更新报名专业 */
    updateStudent () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          updateStudent({ ...this.formData, id: this.updateMajorData.id }).then((res) => {
            this.$emit('closeDialog', {
              isOpen: false,
              type: 'success'
            })
            this.$message({
              message: '添加成功',
              type: 'success'
            })
          })
        }
      })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.$emit('closeDialog', { isOpen: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-coantainer {
  ::v-deep .el-dialog {
    &__body {
      max-height: 500px;
      overflow: auto;
    }
  }
}
</style>
