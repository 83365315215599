<template>
  <!-- 更改报名时间 -->
  <div class="page-coantainer">
    <el-dialog
      title="更改报名时间"
      :visible.sync="signUpTimeData.isOpen"
      width="30%"
      :before-close="handleClose"
    >
      <div class="dialog-body">
        <el-form ref="form" :model="formData" label-width="80px">
          <el-form-item label="报名时间" prop="signUpTime">
            <!-- <el-date-picker
              type="datetime"
              placeholder="开始日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="formData.createTime"
            ></el-date-picker> -->

            <el-date-picker
              v-model="formData.createTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="updateSignUpTime">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updateCreateTime } from '@/api/api.js'
export default {
  props: {
    signUpTimeData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        id: '',
        createTime: ''
      }
    }
  },
  methods: {
    // 更新报名时间
    updateSignUpTime () {
      this.formData.id = this.signUpTimeData.id
      this.$refs.form.validate(valid => {
        if (valid) {
          updateCreateTime(this.formData).then(res => {
            this.$emit('closeDialog', {
              isOpen: false,
              type: 'success'
            })
            this.$message({
              message: '更改成功',
              type: 'success'
            })
          })
        }
      })
    },
    /* 关闭弹窗 */
    handleClose () {
      this.$emit('closeDialog', { isOpen: false })
    }
  }
}
</script>

<style></style>
